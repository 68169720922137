import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { MainLayout } from './MainLayout';
import { SimpleLayout } from './SimpleLayout';

import { Services } from "./components/Services"
import { Promo } from "./components/Promo"
import { Map } from "./components/Map"
import { FaqComponent } from "./components/FaqComponent"
import { Fleet } from "./components/Fleet"
import { Book } from "./components/Book"

import LuxuryCarServiceLA from './pages/LuxuryCarServiceLA';
import AirportTransfer from './pages/AirportTransfer';

export const App = () => {
    return (
        <Router>
            <Routes>
              {/* Главная страница с полным макетом */}
              <Route path="/" element={
                <MainLayout>
                  <Book/>
                  <Services/>
                  <Promo/>
                  <Fleet/>
                  <Map/>
                  <FaqComponent/>
                </MainLayout>
              } />

              {/* Внутренние страницы без большого хиро-контента */}
              <Route path="/luxury-car-service-la" element={
                <SimpleLayout>
                  <LuxuryCarServiceLA />
                </SimpleLayout>
              } />
              <Route path="/airport-transfer" element={
                <SimpleLayout>
                  <AirportTransfer />
                </SimpleLayout>
              } />
            </Routes>
        </Router>
    );
}
