import React from 'react';
import { Bavbar } from "./components/Bavbar"
import { Footer } from "./components/Footer"


export const SimpleLayout = ({ children }) => {
    return (
      <>
        <Bavbar />
      <div className="container mx-auto px-4 sm:px-2">
        {children}
      </div>
      <Footer />
      </>
    );
  };