// MainLayout.jsx
import React from 'react';
import { Header } from "./components/Header"
import { Footer } from "./components/Footer"

export const MainLayout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="container mx-auto px-4 sm:px-2">
        {children}
      </div>
      <Footer />
    </>
  );
};